<template>
  <modal
    id="modalCriar"
    titulo="Formulário"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
    tamanho="lg"
  >
    <template>
      <form>
        <b-container>
          <!-- INFORMAÇÕES PESSOAIS -->
          <h5>{{ $t('RH.INFORMACOES_PESSOAIS') }}</h5>
          <hr />
          <b-row>
            <b-col cols="12" md="6">
              <input-select-multiple
                ref="pessoa"
                v-model="form.pessoaId"
                :placeholder="$t('GERAL.DIGITE_OU_SELECIONE')"
                :label="$t(`ACADEMICO.PESSOA`)"
                :options="opcoes.pessoas"
                required
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-select-search
                ref="tipoFuncionario"
                v-model="form.tipoFuncionario"
                :label="$t(`RH.TIPO_FUNCIONARIO`)"
                :placeholder="$t('GERAL.DIGITE_OU_SELECIONE')"
                :options="opcoes.tiposFuncionario"
                required
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-select-search
                ref="grauInstrucao"
                v-model="form.grauInstrucao"
                :label="$t(`ACADEMICO.GRAU_INSTRUCAO`)"
                :placeholder="$t('GERAL.DIGITE_OU_SELECIONE')"
                :options="opcoes.grauInstrucaoEnums"
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-select-search
                ref="cargos"
                v-model="form.cargo"
                :label="$t(`ACADEMICO.CARGOS`)"
                :placeholder="$t('GERAL.DIGITE_OU_SELECIONE')"
                :options="opcoes.cargosEnum"
                required
              />
            </b-col>
          </b-row>
          <hr />
          <h5>{{ $t('RH.DOCUMENTOS') }}</h5>
          <b-row>
            <b-col cols="12" md="6">
              <input-mask
                ref="carteiraProfissional"
                v-model="form.carteiraProfissional"
                :label="$t('ACADEMICO.CARTEIRA_PROFISSIONAL')"
                :placeholder="$t('ACADEMICO.CARTEIRA_PROFISSIONAL_PLACE')"
                :mask="['#######/####']"
                required
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-mask
                ref="tituloEleitor"
                v-model="form.tituloEleitor"
                :label="$t('ACADEMICO.TITULO_ELEITOR')"
                :placeholder="$t('ACADEMICO.TITULO_ELEITOR_PLACE')"
                :mask="['############']"
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-mask
                ref="secao"
                v-model="form.secao"
                :label="$t('ACADEMICO.SECAO_ELEITORAL')"
                :placeholder="$t('ACADEMICO.SECAO_ELEITORAL_PLACE')"
                :mask="['####']"
                required
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-mask
                ref="reservista"
                v-model="form.reservista"
                :label="$t('ACADEMICO.NUM_RESERVISTA')"
                :placeholder="$t('ACADEMICO.NUM_RESERVISTA_PLACE')"
                :mask="['######']"
              />
            </b-col>
          </b-row>
          <!-- INFORMAÇÕES BANCÁRIAS -->
          <hr />

          <h5>{{ $t('RH.INFORMACOES_BANCARIAS') }}</h5>
          <b-row>
            <b-col cols="12" md="6">
              <input-select-multiple
                ref="bancos"
                v-model="form.banco"
                :label="$t(`ACADEMICO.BANCOS`)"
                :placeholder="$t('GERAL.DIGITE_OU_SELECIONE')"
                :options="opcoes.bancos"
                required
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-mask
                ref="conta"
                v-model="form.conta"
                :label="$t('ACADEMICO.CONTA_BANCARIA')"
                :placeholder="$t('ACADEMICO.CONTA_BANCARIA_PLACE')"
                required
                :mask="['########-#']"
              />
            </b-col>

            <b-col cols="12" md="6">
              <input-mask
                ref="agencia"
                v-model="form.agencia"
                :label="$t('ACADEMICO.AGENCIA_CONTA')"
                :placeholder="$t('ACADEMICO.AGENCIA_CONTA_PLACE')"
                :mask="['####']"
                required
              />
            </b-col>
          </b-row>
          <hr />

          <!-- INFORMAÇÕES TITULAÇÕES -->
          <h5>{{ $t('RH.TITULACOES') }}</h5>
          <b-row>
            <b-col cols="12" md="6">
              <input-mask
                ref="registroProfissional"
                v-model="form.registroProfissional"
                :label="$t('ACADEMICO.REGISTRO_PROFISSIONAL')"
                :placeholder="$t('ACADEMICO.REGISTRO_PROFISSIONAL_PLACE')"
                min="3"
                :mask="['################################################']"
                required
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-text
                ref="titulacoes"
                v-model="form.titulacoes"
                type="text"
                :label="$t('ACADEMICO.TITULACOES')"
                :placeholder="$t('ACADEMICO.TITULACOES_PLACE')"
              />
            </b-col>
            <b-col cols="12" md="6">
              <input-text
                ref="diplomas"
                v-model="form.diplomas"
                type="text"
                :label="$t('ACADEMICO.DIPLOMAS')"
                :placeholder="$t('ACADEMICO.DIPLOMAS_PLACE')"
              />
            </b-col>
          </b-row>
        </b-container>
      </form>
    </template>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import FuncionarioService from '@/common/services/funcionario/funcionario.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import BancosService from '@/common/services/bancos/bancos.service.js';
import PessoaService from '@/common/services/pessoa/pessoa.service.js';

// Components:
import Modal from '@/components/modal/Modal.vue';
import {
  InputSelectSearch,
  InputSelectMultiple,
  InputText,
  InputMask,
} from '@/components/inputs';

export default {
  name: 'Formulario',
  mixins: [VerificacaoPreenchimento],
  props: {
    exibir: { type: Boolean, default: false },
    id: { type: String, default: '' },
    dadosPreenchidos: { type: Object, default: Object },
  },
  components: {
    Modal,
    InputSelectSearch,
    InputSelectMultiple,
    InputText,
    InputMask,
  },
  data() {
    return {
      refs: [],
      form: {
        tipoFuncionario: {},
        grauInstrucao: {},
        cargo: {},
        banco: {},
        pessoaId: {},
        carteiraProfissional: '',
        tituloEleitor: '',
        secao: '',
        reservista: '',
        registroProfissional: '',
        conta: '',
        agencia: '',
        titulacoes: '',
        diplomas: '',
      },
      opcoes: {
        grauInstrucaoEnums: [],
        cargosEnum: [],
        bancos: [],
        pessoas: [],
      },
    };
  },

  mounted() {
    this.getGrauDeInstrucaoEnum();
    this.getTiposFunctionarioEnum();
    this.getCargosEnum();
    this.getBancos();
    this.getPessoas();
  },
  watch: {
    dadosPreenchidos: 'preenchimento',
  },
  methods: {
    preenchimento() {
      this.form.pessoaId.text = this.dadosPreenchidos.nome ?? '';
      this.form.pessoaId.value = this.dadosPreenchidos.pessoaId;
      this.form.cargo.text = this.dadosPreenchidos.cargoDescricao ?? '';
      this.form.cargo.value = this.dadosPreenchidos.cargoId ?? '';
      this.form.tipoFuncionario.text =
        this.dadosPreenchidos.tipoFuncionarioDescricao ?? '';
      this.form.tipoFuncionario.value =
        this.dadosPreenchidos.tipoFuncionarioId ?? '';
      this.form.grauInstrucao.text =
        this.dadosPreenchidos.grauInstrucaoDescricao ?? '';
      this.form.grauInstrucao.value =
        this.dadosPreenchidos.grauInstrucaoId ?? '';
      this.form.banco.text = this.dadosPreenchidos.banco?.descricao ?? '';
      this.form.banco.value = this.dadosPreenchidos.banco?.id ?? '';
      this.form.agencia = this.dadosPreenchidos.agencia ?? '';
      this.form.titulacoes = this.dadosPreenchidos.titulacoes ?? '';
      this.form.registroProfissional =
        this.dadosPreenchidos.registroProfissional ?? '';
      this.form.reservista = this.dadosPreenchidos.reservista ?? '';
      this.form.tituloEleitor = this.dadosPreenchidos.tituloEleitor ?? '';
      this.form.conta = this.dadosPreenchidos.conta ?? '';
      this.form.secao = this.dadosPreenchidos.secao ?? '';
      this.form.diplomas = this.dadosPreenchidos.diplomas ?? '';
      this.form.carteiraProfissional =
        this.dadosPreenchidos.carteiraProfissional ?? '';
    },

    fecharModal() {
      this.limparFormulario();
      this.$emit('fechar');
    },

    salvar() {
      if (!this.validarFormulario()) return;

      if (this.dadosPreenchidos.id) {
        this.editar();
      } else {
        this.criar();
      }
    },

    criar() {
      const dadosFormatados = {
        ...this.form,
        pessoaId: this.form.pessoaId.value,
        bancoId: this.form.banco.value,
        cargo: this.form.cargo.value,
        tipoFuncionario: this.form.tipoFuncionario.value,
        grauInstrucao: this.form.grauInstrucao.value,
        conta: helpers.removerMascara(this.form.conta),
        carteiraProfissional: helpers.removerMascara(
          this.form.carteiraProfissional
        ),
      };

      this.$store.dispatch(START_LOADING);
      FuncionarioService.criar(dadosFormatados)
        .then((result) => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t(result?.data.mensagem)
          );

          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.$emit('salvar');
        });
    },
    editar() {
      const dadosFormatados = {
        ...this.form,
        pessoaId: this.form.pessoaId.value,
        bancoId: this.form.banco.value,
        cargo: this.form.cargo.value,
        tipoFuncionario: this.form.tipoFuncionario.value,
        grauInstrucao: this.form.grauInstrucao.value,
        conta: helpers.removerMascara(this.form.conta),
        carteiraProfissional: helpers.removerMascara(
          this.form.carteiraProfissional
        ),
      };

      this.$store.dispatch(START_LOADING);
      FuncionarioService.atualizar(dadosFormatados)
        .then((result) => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t(result?.data.mensagem)
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.$emit('salvar');
        });
    },
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getGrauDeInstrucaoEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('grau-instrucao')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.grauInstrucaoEnums = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCargosEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('cargos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.cargosEnum = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTiposFunctionarioEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-funcionarios')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tiposFuncionario = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getBancos() {
      this.$store.dispatch(START_LOADING);
      BancosService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            id: row.id,
            codigoBanco: row.codigoBanco,
            descricao: row.descricao,
            value: row.id,
            text: `${row.descricao} - ${row.codigoBanco}`,
          }));
          this.opcoes.bancos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getPessoas() {
      this.$store.dispatch(START_LOADING);
      PessoaService.listarSemPaginacao()
        .then(({ data }) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.nome,
          }));
          this.opcoes.pessoas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    limparFormulario() {
      this.form = {
        tipoFuncionario: {},
        grauInstrucao: {},
        cargo: {},
        banco: {},
        pessoaId: {},
        carteiraProfissional: '',
        tituloEleitor: '',
        secao: '',
        reservista: '',
        registroProfissional: '',
        conta: '',
        agencia: '',
        titulacoes: '',
        diplomas: '',
      };
    },
  },
};
</script>
