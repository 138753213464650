<template>
  <topbar
    :titulo="$t('RH.FUNCIONARIOS')"
    :subtitulo="$t('RH.FUNCIONARIO_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4">
        <b-button @click="cadastrarFuncrionario()" variant="info" class="">
          {{ $t('RH.CADASTRAR_FUNCIONARIO') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  props: {
    breadcrumbs: { type: Array, default: Array },
  },
  components: { Topbar },
  methods: {
    cadastrarFuncrionario() {
      this.$emit('cadastrar-funcionario', true);
    },
  },
};
</script>
