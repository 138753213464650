import ApiService from '@/common/api/api.service';

let apiBasePath = 'Funcionario';

const FuncionarioService = {
  // Listar funcionário por tipo e filtrar por status e nome paginado
  async buscarPorId(id) {
    const { data } = await ApiService.get(`${apiBasePath}/${id}`);
    return data;
  },
  
  async listarFuncionarioPorTipo(filtro = {}, paginacao) {
    const { tamanhoDaPagina, numeroDaPagina } = paginacao;
    const { nome, status, tipoFuncionario } = filtro;

    const params = {
      TamanhoDaPagina: tamanhoDaPagina,
      NumeroDaPagina: numeroDaPagina,
      ...(nome && { nome: nome }),
      ...(status && { status: status }),
      ...(tipoFuncionario && { tipoFuncionario: tipoFuncionario }),
    };

    const { data } = await ApiService.get(
      `${apiBasePath}/${filtro.tipoFuncionario}`,
      {
        params,
      }
    );

    return data;
  },

  async criar(dados) {
    const { data } = await ApiService.post(`${apiBasePath}`, dados);
    return data;
  },

  async atualizar(dados) {
    const { data } = await ApiService.put(`${apiBasePath}`, dados);
    return data;
  },

  async excluir(id) {
    const { data } = await ApiService.delete(`${apiBasePath}/${id}`);
    return data;
  },
};

export default FuncionarioService;
