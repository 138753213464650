<template>
  <!--
      Filtra por nome e status
    -->
  <div class="row">
    <!-- Add 'align-items-center' class here -->
    <div class="col-12 col-md-3">
      <input-select
        ref="tipoFuncionario"
        :label="$t('RH.TIPO_FUNCIONARIO')"
        :options="opcoes.tipoFuncionarios"
        v-model="filtro.tipoFuncionario"
        required
      />
    </div>
    <div class="col-12 col-md-2">
      <input-select
        ref="situacao"
        :label="$t('RH.SITUACAO')"
        :options="opcoes.status"
        v-model="filtro.status"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-text
        ref="nome"
        v-model="filtro.nome"
        :label="$t(`RH.NOME`)"
        :placeholder="$t('GERAL.FILTRAR_POR_NOME')"
        @enter="filtrar"
      />
    </div>
    <div class="col-12 col-md-auto">
      <b-button
        variant="primary"
        @click="filtrar"
        class="botao-acao-filtro w-100 my-3"
      >
        {{ $t('GERAL.PESQUISAR') }}
      </b-button>
    </div>
    <div class="col-12 col-md-auto">
      <b-button
        class="botao-acao-filtro w-100 my-3"
        variant="secondary"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </div>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

//services
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import { InputText, InputSelect } from '@/components/inputs';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  components: {
    InputText,
    InputSelect,
  },
  data() {
    return {
      filtro: {
        nome: undefined,
        status: undefined,
        tipoFuncionario: undefined,
      },
      opcoes: {
        status: [
          {
            text: this.$t('ACADEMICO.SITUACAO_ATIVO'),
            value: 'Ativo',
          },
          {
            text: this.$t('ACADEMICO.SITUACAO_INATIVO'),
            value: 'Inativo',
          },
        ],
        tipoFuncionarios: [],
      },
      desabilitar: {
        turma: true,
      },
    };
  },
  mounted() {
    this.getTipoFuncionarios();
  },
  watch: {
    filtro: {
      handler(value) {
        this.$emit('atualizar-filtro', value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // FUNÇÕES FILTRO:
    filtrar() {
      if (!this.validarFiltro()) return;
      return this.$emit('filtro', this.filtro);
    },
    limparFiltro() {
      this.filtro.nome = undefined;
      this.filtro.status = undefined;
      this.filtro.tipoFuncionario = undefined;
    },
    // FUNÇÕES AUXILIARES:
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
    // OBTER DADOS
    getTipoFuncionarios() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-funcionarios')
        .then(({ data }) => {
          data = data.map((row) => ({
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoFuncionarios = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
