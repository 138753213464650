<template>
  <div>
    <titulo-funcionarios
      :breadcrumbs="breadcrumbs"
      @cadastrar-funcionario="alterarExibirModalCriar"
    />

    <filtro
      @atualizar-filtro="atualizarFiltro"
      @filtro="filtrar"
      @limpar="limparFiltro"
    />

    <b-table
      :items="funcionarios"
      :fields="camposTabela"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      responsive="sm"
      show-empty
      striped
    >
      <template #cell(status)="item">
        <b-badge :variant="item.item.status == 'Ativo' ? 'success' : 'danger'">
          <span class="text-xl">
            {{
              item.item.status === 'Ativo'
                ? $t('ACADEMICO.SITUACAO_ATIVO')
                : $t('ACADEMICO.SITUACAO_INATIVO')
            }}
          </span>
        </b-badge>
      </template>
      <template #cell(titulacoes)="item">
        <span class="text-xl">
          {{
            item.item.titulacoes && item.item.titulacoes != 'undefined'
              ? item.item.titulacoes
              : $t('ACADEMICO.NAO_INFORMADO')
          }}
        </span>
      </template>
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editar(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="confirmarExclusao(item.item)">
            {{ $t('GERAL.DESLIGAR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <paginacao
      v-model="paginacao"
      :total="total"
      @input="pegarFuncionariosFiltrados"
    />

    <modal-criar-funcionario
      :exibir="exibirModalCriar"
      :id="funcionarioId"
      @fechar="alterarExibirModalCriar(false)"
      @salvar="pegarFuncionariosFiltrados"
      :dadosPreenchidos="dadosPreenchidos"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services
import FuncionarioService from '@/common/services/funcionario/funcionario.service';
// Components:
import Filtro from './components/FiltroFuncionarios';
import Paginacao from '@/components/paginacao/Paginacao';
import ModalCriarFuncionario from './components/FormularioFuncionario';
import TituloFuncionarios from '@/views/rh/funcionarios/components/TituloFuncionarios';

export default {
  components: {
    Filtro,
    Paginacao,
    ModalCriarFuncionario,
    TituloFuncionarios,
  },
  computed: {
    breadcrumbs() {
      return this.$route?.params?.linkRetornar === 'rh'
        ? [
            { titulo: this.$t('RH.RECURSOS_HUMANOS') },
            { titulo: this.$t('RH.FUNCIONARIOS') },
          ]
        : [
            { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
            { titulo: this.$t('ACADEMICO.DOCENTES_PERIODO') },
            { titulo: this.$t('RH.FUNCIONARIOS') },
          ];
    },
  },
  data() {
    return {
      funcionarioId: '',
      dadosPreenchidos: {},
      retornar: 'docentes-periodo',
      funcionarios: [],
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
      },
      total: 0,
      camposTabela: [
        {
          key: 'nome',
          label: this.$t('ACADEMICO.NOME'),
          sortable: true,
        },
        {
          key: 'titulacoes',
          label: this.$t('ACADEMICO.TITULACOES'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('ACADEMICO.SITUACAO'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
      filtro: {},
      exibirModalCriar: false,
    };
  },
  methods: {
    // FUNÇÕES FILTRO:
    filtrar() {
      this.pegarFuncionariosFiltrados();
    },
    atualizarFiltro(value) {
      this.filtro = value;
    },
    limparFiltro() {
      this.filtro = {};
    },
    pegarFuncionariosFiltrados() {
      this.$store.dispatch(START_LOADING);
      FuncionarioService.listarFuncionarioPorTipo(this.filtro, this.paginacao)
        .then(({ data }) => {
          this.funcionarios = data.itens;
          this.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => mensagem.showErrors(err))
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // excluir
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CONFIRMAR_EXCLUSAO_DE').formatUnicorn({
          item: item.nome,
        }),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item.id);
      }
    },

    excluir(id) {
      this.$store.dispatch(START_LOADING);
      FuncionarioService.excluir(id)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data);
          this.pegarFuncionariosFiltrados();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // funções modal
    editar(item) {
      this.funcionarioId = item.id;
      this.dadosPreenchidos = item;
      this.alterarExibirModalCriar(true);
    },

    alterarExibirModalCriar(alternador) {
      this.exibirModalCriar = alternador;
    },
  },
};
</script>
